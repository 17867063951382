<template>
  <div class="world calculator-page">
    <div class="wrapper-title">
      <div class="container">
        <button class="text-wilmsLightBlue font-100 block" @click="backToOverview">&larr; {{ $t('Terug naar offerte') }}</button>
        <h1 v-if="quoteNumber">{{ $t('Offerte') + ' ' + quoteNumber }}: {{ $t('Configuratie screens') }}</h1>
        <div class="flex"></div>
      </div>
    </div>

    <div class="calculator-wrapper">
      <div class="container">
        <div class="steps-wrapper">
          <div class="step-buttons">
            <h3>{{ $t('Configuratie') }}</h3>
            <CalculatorButton :activeStep="activeStep" :label=" $t('Type') " :type="'type'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateType())" :label=" $t('Motorisatie') " :type="'motor'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateMotor())" :label=" $t('Configuraties') " :type="'size'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateWidth())" :label=" $t('Doeksoort') " :type="'fabric_types'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateFabricTypes())" :label=" $t('Kleur kast') " :type="'cabinet_colors'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateColors())" :label=" $t('Doek kleur') " :type="'fabric_colors'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateColors())" :label=" $t('Opties') " :type="'options'" @setStep="setStep"/>
          </div>

          <div class="step-contents">
            <div v-if="activeStep === 'type'">
              <h2>{{ $t('Naar welk type screen ben je op zoek?') }}</h2>

              <div class="types-wrapper">
                <div class="types types-shutter types-screen">
                  <button v-for="type in types" :key="type.key" :class="{'active': selectedOptions.type === type.key }" class="type" @click="selectType(type.key)" @dblclick="setStep('motor')">
                    <img v-if="selectedOptions.type === type.key" alt="" class="icon-check" src="@/assets/img/check_solid.svg" width="23">
                    <div class="image">
                      <img :alt="type.label" :src="type.image">
                    </div>
                    <h3>{{ type.label }}</h3>
                  </button>
                </div>
              </div>

              <div class="buttons">
                <button :disabled="!selectedOptions.type" class="btn" @click="setStep('motor')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'motor'">
              <h2>{{ $t('Selecteer het type motor') }}</h2>
              <formulate-input v-model="selectedOptions.engineConnection" :label="$t('Motor')" :options="enginesConnections" :placeholder="$t('Selecteer type motor')" type="select" @change="setEngine"></formulate-input>
              <div class="buttons">
                <button :disabled="!(validateMotor())" class="btn" @click="setStep('size')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'size'">
              <h2>{{ $t('Selecteer jouw breedte en hoogte') }}</h2>
              <formulate-form ref="widthForm" name="widthForm">
                <formulate-input v-model="selectedOptions.measurement_style" :options="{'default': $t('Standaard'), 'cabinet': $t('Kast'), 'cabinet_rails': $t('Kast + geleiders')}" type="radio" @input="setWidths"></formulate-input>

                <div class="shutter-configs">
                  <div v-for="(screen, i) in selectedOptions.screens" :key="'screen-' + i" class="shutter-config">
                    <formulate-input v-model="screen.name" :label="$t('Naam')"/>

                    <div v-if="screen.width && !screen.maxHeight" class="error-message">
                      <span v-if="selectedOptions.measurement_style === 'outside'">
                        {{ $t('Let op: opgegeven breedte is te breed. Hou rekening met de geleiders (+ 110mm).') }}
                      </span> <span v-else>
                        {{ $t('Let op: opgegeven breedte is te breed.') }}
                      </span>
                    </div>

                    <div v-if="screen.height && !screen.price" class="error-message">
                      {{ $t('Er kon geen prijs gevonden worden voor de opgegeven breedte en hoogte.') }}
                    </div>

                    <div class="width-height">
                      <formulate-input
                        v-model="screen.width"
                        :help="$t('De minimale breedte is ') + minWidth + 'mm. ' + $t('De maximale breedte is ') + maxWidth + 'mm.'"
                        :label="$t('Breedte (mm)')"
                        :max="maxWidth"
                        :min="minWidth"
                        :placeholder="$t('Vul een breedte in')"
                        :validation="'required|number|between:' + (minWidth - 1) + ',' + (maxWidth + 1) + ',value'"
                        :validationMessages="{ between: $t('Breedte moet tussen ' + minWidth + ' en ' + maxWidth) }"
                        type="number"
                        @input="setWidth(screen)"
                      ></formulate-input>

                      <formulate-input
                        v-model="screen.height"
                        :help="screen.maxHeight > 0 ? $t('De maximale hoogte is ') + screen.maxHeight + 'mm.' : ''"
                        :label="$t('Hoogte (mm)')"
                        :max="screen.maxHeight"
                        :placeholder="$t('Vul een hoogte in')"
                        :validation="'required|number|max:' + (screen.maxHeight)"
                        :validationMessages="{max: screen.maxHeight > 0 ? $t('Hoogte (mm) moet kleiner of gelijk zijn aan ' + screen.maxHeight) : ''}"
                        type="number"
                        @input="fetchPrice(screen)"
                      ></formulate-input>
                    </div>
                  </div>
                </div>

                <button class="btn btn-add" type="button" @click="addScreen">{{ $t('Screen toevoegen') }}</button>

                <div class="buttons">
                  <button :disabled="!(validateWidth())" class="btn" @click="setStep('fabric_types')">{{ $t('Volgende') }}</button>
                </div>
              </formulate-form>
            </div>

            <div v-if="activeStep === 'fabric_types'">
              <h2>{{ $t('Selecteer de doeksoort') }}</h2>

              <div :key="'screen-fabric-type-' + i" v-for="(screen, i) in selectedOptions.screens">
                <formulate-input :label="screen.name" type="select" :options="screen.fabricTypes" v-model="screen.fabric_type"/>
              </div>

              <div class="buttons">
                <button :disabled="!(validateFabricTypes())" class="btn" @click="setStep('cabinet_colors')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'cabinet_colors'">
              <h2>{{ $t('Selecteer de kastkleur') }}</h2>

              <!--              <formulate-input v-model="selectedOptions.color_alternative" :label="$t('Alternatieve RAL-kleur')" type="checkbox" @change="setAlternativeColor"></formulate-input>-->

              <!--              <div class="colors">-->
              <!--                <div v-for="color in cabinetColors" :key="'cabinet-color-' + color.ral" :class="{ 'active' : selectedOptions.color && selectedOptions.color.ral === color.ral }" class="color-wrapper" @click="selectColor(color)" @dblclick="setStep('fabric_colors')">-->
              <!--                  <Icon v-if="selectedOptions.color && selectedOptions.color.ral === color.ral" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>-->
              <!--                  <div :class="{ 'active' : selectedOptions.color && selectedOptions.color.ral === color.ral }" :style="{ 'background-color': ralColors[color.ral].color.hex }" class="color"></div>-->
              <!--                  <span>{{ color.name }}</span>-->
              <!--                </div>-->
              <!--              </div>-->

              <formulate-input v-model="ralSearch" :placeholder="$t('Ral kleur zoeken')" class="color-search" name="search"></formulate-input>

              <div class="colors">
                <div v-for="color in ralColorsSorted" :key="color.ral" class="color-wrapper" @click="selectColor(color)" @dblclick="setStep('fabric')">
                  <Icon v-if="selectedOptions.color && selectedOptions.color.code === color.code" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>
                  <div :class="{ 'active' : selectedOptions.color && selectedOptions.color.code === color.code }" :style="{ 'background-color': color.color.hex }" class="color"></div>
                  <span>RAL{{ color.code }}</span>
                </div>
              </div>

              <div class="buttons">
                <button :disabled="!(validateColors())" class="btn" @click="setStep('fabric_colors')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'fabric_colors'">
              <h2>{{ $t('Selecteer de kleur van het doek') }}</h2>

              <div class="colors">
                <div v-for="color in fabricColors" :key="'fabric-color-' + color.id" :class="{ 'active' : selectedOptions.fabric_color && selectedOptions.fabric_color.name === color.name }" class="color-wrapper" @click="selectFabricColor(color)" @dblclick="setStep('options')">
                  <Icon v-if="selectedOptions.fabric_color && selectedOptions.fabric_color.name === color.name" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>
                  <!--                  <div :class="{ 'active' : selectedOptions.fabric_color && selectedOptions.fabric_color.ral === color.ral }" :style="{ 'background-color': ralColors[color.ral] && ralColors[color.ral].color.hex }" class="color"></div>-->
                  <div :class="{ 'active' : selectedOptions.fabric_color === color.name }" :style="{ 'background-color': '#EEEEEE'}" class="color">
                    <img v-if="fabricImages[color.name].img" :src="fabricImages[color.name].img" alt="">
                  </div>
                  <span>{{ color.name }}</span>
                </div>
              </div>

              <div class="buttons">
                <button :disabled="!(validateColors())" class="btn" @click="setStep('options')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'options'">
              <h2>{{ $t('Opties') }}</h2>
              <div class="help">{{ $t('Selecteer de opties die je wilt toevoegen') }}</div>
              <v-select ref="tester" v-model="selectedOptions.optionsSelected" :options="options" label="name" multiple @option:selecting="selectOption" @option:deselected="deselectOption"></v-select>

              <div class="option-inputs">
                <div v-for="option in selectedOptions.options" :key="option.id">
                  <div class="icon" @click="deselectOption(option)">
                    <Icon color="#cf5353" icon="clarity:remove-solid"/>
                  </div>
                  <formulate-input v-model="option.amount" :label="$t(option.name)" class="with-suffix" type="number">
                    <template #suffix>
                      <span class="suffix">{{ option.unit_type === 'piece' ? $t('aantal stuks') : $t('aantal lopende meter') }}</span>
                    </template>
                  </formulate-input>
                </div>
              </div>

              <div class="buttons">
                <button class="btn" @click="saveScreens">{{ $t('Configuraties opslaan en terugkeren naar offerte') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalculatorButton from '@/components/world/CalculatorButton'
import { Icon } from '@iconify/vue2'
import { ApiService } from '@/services/admin/api.service'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { DateTime } from 'luxon'
import calculators from '@/calculators'

export default {
  name: 'Calculator',
  mixins: [
    calculators
  ],
  components: {
    Icon,
    CalculatorButton,
    vSelect
  },
  data () {
    return {
      price: null,
      quoteNumber: null,
      calculatorData: null,
      activeStep: 'type',
      cabinetColors: [],
      fabricColors: [],
      fabricTypes: {},
      minWidth: null,
      maxWidth: null,
      types: [
        {
          key: 'zipx85',
          label: 'ZipX85',
          description: '',
          image: require('@/assets/img/calculator/types/zipx85.webp')
        },
        {
          key: 'zipx95',
          label: 'ZipX95',
          description: '',
          image: require('@/assets/img/calculator/types/zipx85.webp')
        },
        {
          key: 'zipx120',
          label: 'ZipX120',
          description: '',
          image: require('@/assets/img/calculator/types/zipx85.webp')
        }
      ],
      engineOptions: [],
      options: [],
      connections: {},
      enginesConnections: {},
      selectedOptions: {
        name: '',
        engineConnection: null,
        type: '',
        engine: null,
        measurement_style: '',
        color: null,
        color_alternative: false,
        fabric_color: null,
        options: [],
        optionsData: [],
        optionsSelected: [],
        screens: [
          {
            width: null,
            height: null,
            name: '',
            price: 0,
            maxHeight: null,
            fabricTypes: {},
            fabric_type: null
          }
        ]
      },
      datetime: DateTime,
      ralSearch: ''
    }
  },
  async mounted () {
    await this.setDealer()
    await this.fetchQuoteNumber()
  },
  methods: {
    async fetchQuoteNumber () {
      await ApiService.fetchQuoteNumber(this.$route.params.quoteId).then(res => {
        this.quoteNumber = res.data
      })
    },
    async addScreen () {
      this.selectedOptions.screens.push({
        width: null,
        height: null,
        name: '',
        price: 0,
        maxHeight: 0
      })
    },
    async saveScreens () {
      for (const screen of this.selectedOptions.screens) {
        const screenData = { ...this.selectedOptions, ...screen }
        screenData.quote_id = this.$route.params.quoteId

        const res = await ApiService.createScreen(screenData)

        if (res.status === 200) {
          const screenDb = res.data
          let colorData = null

          if (screenData.color) {
            colorData = {
              component: 'cabinet',
              ral: screenData.color.code
              // color_id: screenData.color.id
            }
          } else if (this.selectedOptions.color_alternative) {
            colorData = {
              component: 'cabinet'
            }
          }

          await ApiService.createScreenColor(screenDb.id, colorData)
          await ApiService.createScreenColor(screenDb.id, {
            color_id: screenData.fabric_color.id,
            component: 'fabric'
          })

          for (const option of screenData.options) {
            const obj = {
              option_id: option.id,
              amount: option.amount
            }
            await ApiService.createScreenOption(screenDb.id, obj)
          }
        }
      }

      await this.$router.push({
        name: 'ww.quotes.detail',
        params: { id: this.$route.params.quoteId },
        query: { tab: 'overview' }
      })
    },
    async fetchPrice (screen) {
      if (screen.width && screen.height) {
        await ApiService.fetchScreenPrice(this.selectedOptions.type, this.selectedOptions.engine, this.selectedOptions.connection, screen.width, screen.height).then(res => {
          screen.price = res.data
        })
      }

      const res = await ApiService.fetchFabricTypes(this.selectedOptions.type, this.selectedOptions.engine, this.selectedOptions.connection, screen.width, screen.height)
      screen.fabricTypes = {}

      if (res.data) {
        for (const fabricType of res.data.sort((a, b) => a > b ? 1 : -1)) {
          screen.fabricTypes[fabricType] = this.$t(fabricType)
        }
      }
    },
    backToOverview () {
      this.setStep(this.activeStep)
      this.$router.push({ name: 'ww.quotes.detail', params: { id: this.$route.params.quoteId } })
    },
    validateType () {
      if (this.selectedOptions.type) {
        return true
      }

      return false
    },
    validateMotor () {
      if (this.validateType() && this.selectedOptions.engine) {
        return true
      }

      return false
    },
    validateWidth () {
      if (!this.validateMotor()) {
        return false
      }

      for (const screen of this.selectedOptions.screens) {
        if (!(screen.width >= this.minWidth && screen.width <= this.maxWidth)) {
          return false
        }

        if (screen.height > screen.maxHeight) {
          return false
        }

        if (!screen.price) {
          return false
        }
      }

      return true
    },
    validateFabricTypes () {
      if (!this.validateWidth()) {
        return false
      }

      for (const screen of this.selectedOptions.screens) {
        if (!screen.fabric_type) {
          return false
        }
      }

      return true
    },
    validateColors () {
      if (this.validateWidth() && (this.selectedOptions.color || this.selectedOptions.color_alternative)) {
        return true
      }

      return false
    },
    async setStep (step) {
      this.calculatorData = { ...this.calculatorData, ...this.selectedOptions }
      await this.setEngine()
      this.activeStep = step
    },
    async selectType (type, resetEngineConnection = true) {
      this.selectedOptions.type = type

      if (resetEngineConnection) {
        this.selectedOptions.engine = null
        this.selectedOptions.connection = null
      }

      await this.fetchCalculatorEnginesConnections()
      // await this.setWidth()
    },
    async fetchCalculatorEnginesConnections () {
      await ApiService.fetchScreenEnginesConnections(this.selectedOptions.type).then(async res => {
        this.enginesConnections = {}

        res.data.forEach(engineConnection => {
          this.enginesConnections[engineConnection.engine + '_' + engineConnection.connection] = this.$t(engineConnection.engine) + ' - ' + this.$t(engineConnection.connection)
        })

        if (Object.keys(this.enginesConnections).length === 1) {
          this.selectedOptions.engineConnection = Object.keys(this.enginesConnections)[0]
          await this.setEngine()
        }
      })
    },
    async setEngine () {
      if (this.selectedOptions.engineConnection) {
        const engineConnection = this.selectedOptions.engineConnection.split('_')
        this.selectedOptions.engine = engineConnection[0]
        this.selectedOptions.connection = engineConnection[1]
      }

      await this.fetchCalculatorInfo()
      await ApiService.fetchScreenOptions(this.selectedOptions.type).then(res => {
        this.options = res.data.map(option => {
          option.internal_name = option.name
          option.name = this.$t(option.name)
          return option
        }).sort((a, b) => a.name > b.name ? 1 : -1)
      })
    },
    async setAlternativeColor () {
      this.selectedOptions.color = null
    },
    async selectColor (color) {
      this.selectedOptions.color_alternative = false
      this.selectedOptions.color = color
    },
    async selectFabricColor (color) {
      this.selectedOptions.fabric_color = color
    },
    async fetchCalculatorInfo () {
      await ApiService.fetchScreenInfo(this.selectedOptions.type, this.selectedOptions.engine).then((res) => {
        if (res.data) {
          this.minWidth = res.data.width[0]
          this.maxWidth = res.data.width[1]
          this.cabinetColors = res.data.colors.cabinet
          this.fabricColors = res.data.colors.fabric
        }
      })
    },
    async setWidth (screen) {
      if (this.validateMotor() && screen.width) {
        await ApiService.fetchScreenHeight(this.selectedOptions.type, this.selectedOptions.engine, this.selectedOptions.connection, screen.width, this.selectedOptions.measurement_style).then(res => {
          screen.maxHeight = res.data || 0
        })
      }
    },
    async setWidths () {
      if (this.validateMotor()) {
        for (const screen of this.selectedOptions.screens) {
          if (screen.width) {
            await ApiService.fetchScreenHeight(this.selectedOptions.type, this.selectedOptions.engine, this.selectedOptions.connection, screen.width, this.selectedOptions.measurement_style).then(res => {
              screen.maxHeight = res.data || 0
            })
          }
        }
      }
    },
    /** options methods **/
    async selectOption (option) {
      this.selectedOptions.options.push(
        {
          id: option.id,
          name: option.internal_name,
          amount: 1,
          unit_type: option.unit_type
        }
      )
    },
    deselectOption (option) {
      this.selectedOptions.optionsSelected.splice(this.selectedOptions.optionsSelected.findIndex(opt => opt.id === option.id), 1)
      this.selectedOptions.options.splice(this.selectedOptions.options.findIndex(opt => opt.id === option.id), 1)
    }
  },
  computed: {
    fabricImages () {
      const imagesMap = []
      for (const fabricColor of this.fabricColorsScreens) {
        imagesMap[fabricColor.name] = fabricColor
      }
      return imagesMap
    },
    ralColorsSorted () {
      if (this.ralSearch === '') {
        return []
      }

      const filteredRals = {}
      Object.keys(this.ralColors).forEach(ral => {
        if (ral.includes(this.ralSearch) || ('RAL' + ral).includes(this.ralSearch)) {
          filteredRals[ral] = this.ralColors[ral]
        }
      })
      return filteredRals
    }
  }
}
</script>
